<template>
  <tr
    :class="isMobile ? 'v-data-table__mobile-table-row' : getClass"
    @click="$emit('click')"
  >
    <template v-if="isMobile">
      <td v-if="showSelect">
        <div style="text-align: right">
          <v-simple-checkbox
            :value="selected"
            @input="$emit('toggle-selected', item)"
          />
        </div>
      </td>
      <td
        class="v-data-table__mobile-row"
        v-for="(header, index) in headers"
        :key="index"
      >
        <div class="v-data-table__mobile-row__header">
          {{ header.text }}
        </div>
        <div class="v-data-table__mobile-row__cell">
          <slot :item="item" :name="columnName(header)">
            <div :style="getAlignment(header)">
              {{ getNonSlotValue(item, header) }}
            </div>
          </slot>
        </div>
      </td>
    </template>

    <template v-else>
      <td v-if="showSelect">
        <div style="text-align: center">
          <v-simple-checkbox
            :value="selected"
            @input="$emit('toggle-selected', item)"
          />
        </div>
      </td>

      <td v-for="(header, index) in headers" :key="index">
        <slot :item="item" :name="columnName(header)">
          <div :style="getAlignment(header)">
            {{ getNonSlotValue(item, header) }}
          </div>
        </slot>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: 'DataTableRowHandler',
  components: {},
  props: {
    itemClass: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 960,
    }
  },
  computed: {
    getClass() {
      return this.itemClass
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`
    },
    getAlignment(header) {
      const align = header.align ? header.align : 'left'
      return `text-align: ${align}`
    },
    getNonSlotValue(item, header) {
      const val = item[header.value]

      if (val) {
        return val
      }

      return ''
    },

    checkMobile() {
      this.isMobile = window.innerWidth < 960
    },
  },
}
</script>
